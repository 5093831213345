@config "../../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .accent {
    @apply tw-bg-accent tw-text-neutral-800;
  }

  .neutral {
    @apply tw-bg-neutral-50 tw-text-neutral-800;
  }

  .primary {
    @apply tw-bg-primary tw-text-neutral-800;
  }

  .danger {
    @apply tw-bg-neutral-50 tw-text-danger;
  }

  .danger-invert {
    @apply tw-bg-danger tw-text-white;
  }

  .btn-icon {
    @apply tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-p-0 tw-shadow-button;
  }

  .input-field {
    @apply tw-h-12 tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-px-6 read-only:!tw-border-0 read-only:!tw-border-neutral-100 read-only:!tw-bg-neutral-50 focus:tw-border-[1.5px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none;
  }

  .input-error {
    @apply tw-text-xs;
  }

  .input-error-border {
    @apply tw-border-[1.5px] tw-border-solid tw-border-danger;
  }

  .right-round-border {
    @apply tw-rounded-l-2xl tw-rounded-r-[50px];
  }

  .left-round-border {
    @apply tw-rounded-l-[50px] tw-rounded-r-2xl;
  }

  .tooltip-label {
    @apply tw-whitespace-nowrap tw-font-semibold;
  }

  .panel-dropdown {
    @apply tw-absolute tw-top-11 tw-flex tw-w-full tw-flex-col tw-gap-1 tw-bg-white tw-px-1 tw-shadow-button;
  }

  .round-corner-button {
    @apply tw-h-[40px] tw-w-[112px] tw-rounded-[8px] xs:tw-h-[36px] xs:tw-w-[102px];
  }

  .button-resize {
    @apply tw-w-1/2 xs:tw-w-full;
  }

  /**
    @Sinan Stepper styles start: Find a better way to organize css.
    */
  .step-holder {
    @apply tw-flex tw-h-full tw-flex-grow tw-flex-col;
  }

  .step-description {
    @apply tw-py-2 tw-leading-[150%] tw-tracking-[0.0125em] tw-text-neutral-800;
  }

  .section-gap {
    @apply tw-mt-12 xs:tw-mt-6;
  }

  .section-header {
    @apply tw-block tw-pb-1 tw-text-2xl tw-font-medium tw-leading-[125%] tw-text-neutral-800 xs:tw-text-xl;
  }

  .secondary-buttons-holder {
    @apply tw-absolute tw-bottom-12 tw-flex tw-w-full tw-flex-grow tw-items-end tw-justify-between tw-pb-2;
  }

  .step-action-button {
    @apply tw-flex tw-h-[48px] tw-w-[48%] tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-bg-neutral-50 tw-px-6 tw-py-3;
  }

  .accordion-list-item {
    @apply tw-mb-1 tw-flex tw-items-start tw-gap-2 tw-text-xs tw-tracking-[0.005em] tw-text-neutral-800;
  }
  .accordion-list-item-bullet {
    @apply tw-mt-1.5 tw-inline-block tw-h-[4px] tw-w-[4px] tw-flex-shrink-0 tw-rounded-full tw-bg-neutral-800;
  }
  .accordion-list-description {
    @apply tw-text-xs tw-font-medium tw-leading-[125%] tw-tracking-[0.005em] tw-text-neutral-800 tw-mix-blend-normal;
  }

  .round-corner-bordered-data-card {
    @apply tw-relative tw-flex tw-h-16 tw-items-end tw-justify-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-teal-500 tw-bg-[rgba(200,242,242,0.2)] tw-p-3;
  }

  .tool-tip-component-container {
    @apply tw-absolute tw-right-0 tw-top-[40px] tw-flex tw-h-[56px] tw-flex-col tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-px-3 tw-py-2 tw-shadow-md;
  }
  /**
   @Sinan Stepper styles start
   */
  .widget-menu-selected {
    @apply tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-bg-white;
  }
}
